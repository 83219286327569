import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

export default () => {
    return (
        <ThemeToggler>
            {
                ({ theme, toggleTheme }) => {
                    return theme !== null ? (
                        <div className="toggle">
                            <input id="toggle" className="visually-hidden" type="checkbox"
                                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                                checked={theme === 'dark'}
                            />{' '}
                            <label className={`${theme === 'dark' ? 'active' : ''}`} htmlFor="toggle"><span className="visually-hidden">Toggle dark mode</span></label>
                        </div>) : (<div></div>);
                }
            }
        </ThemeToggler>
    );
}