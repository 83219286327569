import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import Container from "./container";

function Layout({ data, children, frontmatter = {} }) {
    const {
        site: {
            siteMetadata: {
                title: siteTitle
            },
        },
    } = data;

    const {
        title = siteTitle
    } = frontmatter;

    return (
        <div className="page">
            <Helmet title={title}>
                <html lang="en" />
                <noscript>
                    This site runs best with JavaScript enabled.
                </noscript>
            </Helmet>
            <Header title={title} />
            <Container verticalMargin>{children}</Container>
            <Footer />
        </div>
    );
}

export default function LayoutWithSiteData(props) {
    return (
        <StaticQuery
            query={graphql`
                {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                            keywords
                        }
                    }
                }
            `}
            render={data => <Layout data={data} {...props} />}
        />
    );
}
