import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { rhythm } from "../utils/typography";
import Container from "./container";
import Toggle from "./toggle";

const Header = ({ title }) => (
    <StaticQuery
        query={query}
        render={data => {
            const { author } = data.site.siteMetadata;
            return (
                <header className="header">
                    <Container>
                        <nav className="nav">
                            <Link
                                to="/"
                                style={{
                                    display: `flex`,
                                    alignItems: `center`,
                                    textShadow: `none`,
                                    textDecoration: `none`,
                                    backgroundImage: `none`,
                                }}
                            >
                                <Image
                                    fixed={data.avatar.childImageSharp.fixed}
                                    alt={author}
                                    style={{
                                        marginRight: rhythm(1 / 2),
                                        marginBottom: 0,
                                        minWidth: 40,
                                        borderRadius: `100%`,
                                    }}
                                    imgStyle={{
                                        borderRadius: `50%`,
                                    }}
                                />
                                <p className="site-title">{title}</p>
                            </Link>
                            <Toggle />
                        </nav>
                    </Container>
                </header>
            );
        }}
    />
);

const query = graphql`
    query {
        avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
            childImageSharp {
                fixed(width: 40, height: 40) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        site {
            siteMetadata {
                author
            }
        }
    }
`;

export default Header;
