import React from "react";

const Container = props => {
    const {
        verticalMargin = false,
        children,
        ...restProps
    } = props;

    return (
        <div
            className={`container ${verticalMargin && 'container--has-vertical-margin'}`}
            {...restProps}
        >
            {children}
        </div >
    );
};

export default Container;
