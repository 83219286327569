import Typography from "typography";
import "../fonts/fonts.css";
import "../utils/global.css";


export const fonts = {
    headings: {
        light: "Montserrat Light",
        regular: "Montserrat",
        medium: "Montserrat Medium",
        bold: "Montserrat Bold",
    },
    body: {
        light: "Merriweather Light",
        regular: "Merriweather",
        bold: "Merriweather Black",
    },
};

const typography = new Typography({
    baseFontSize: "18px",
    baseLineHeight: 1.55,
    headerLineHeight: 1.4,
    headerFontFamily: [fonts.headings.light, "sans serif"],
    bodyFontFamily: [fonts.body.regular, "sans serif"],
    headerColor: "hsl(212, 56%, 16%)",
    bodyColor: "hsl(212, 56%, 16%)",

    overrideStyles: ({ rhythm }) => ({
        "h1, h2, h3": {
            color: "var(--textTitle)",
            fontFamily: fonts.headings.medium,
        },
        "h5,h6": {
            lineHeight: 1,
        },
        "h1,h2,h3,h4": {
            lineHeight: 1.25,
            marginTop: rhythm(2),
            marginBottom: rhythm(1 / 2),
        },
        h3: {
            fontSize: rhythm(.8),
        },
        p: {
            lineHeight: 1.7,
        },
        a: {
            color: "var(--textLinkColor)",
            textShadow: "none",
            textDecoration: "none",
            backgroundImage: "none",
        },
        "a:hover": {
            textDecoration: "underline"
        },
        strong: {
            fontFamily: fonts.body.bold,
            fontStyle: "bold",
        },
    }),
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
    typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
