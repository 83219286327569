import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import profilePic from "../../content/assets/profile-pic.jpg";

const query = graphql`
    query GetSiteMetadata {
        site {
            siteMetadata {
                title
                author
                description
                keywords
                siteUrl
                social {
                    twitter
                }
            }
        }
    }
`;

function SEO({ image, title, description, keywords, slug, lang = "en" }) {
    return (
        <StaticQuery
            query={query}
            render={data => {
                const { siteMetadata } = data.site;

                const metaTitle = `${title} — ${siteMetadata.title}` || `${siteMetadata.title} - A blog by Poul H. Hansen`;
                const metaDescription = description || siteMetadata.description;
                const metaKeywords = keywords || siteMetadata.keywords;
                const url = `${siteMetadata.siteUrl}${slug}`;
                const metaImage = image
                    ? `${siteMetadata.siteUrl}/${image}`
                    : `${siteMetadata.siteUrl}/${profilePic}`;

                return (
                    <Helmet
                        htmlAttributes={{ lang }}
                    >
                        {/* General tags */}
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDescription} />
                        <meta name="keywords" content={metaKeywords} />

                        {/* OpenGraph tags */}
                        <meta property="og:url" content={url} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={metaTitle || siteMetadata.title} />
                        <meta property="og:description" content={metaDescription} />
                        { metaImage ? <meta property="og:image" content={metaImage} /> : null }

                         {/* Twitter Card tags */}
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:creator" content={siteMetadata.social.twitter} />
                        <meta name="twitter:title" content={metaTitle} />
                        <meta name="twitter:description" content={metaDescription} />
                        { metaImage ? <meta name="twitter:image" content={metaImage} /> : null }
                    </Helmet>
                );
            }}
        />
    );
}

SEO.defaultProps = {
    title: ``,
    slug: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    image: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default SEO;
